.btn {
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: uppercase;
  background: transparent;
  color: #012B53;
  border: 2px solid #012B53;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #012B53;
  z-index: -1;
  transition: 0.5s;
}

.btn:hover::before {
  width: 100%;
}
